<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <!-- 头部 -->
        <div class="justify-between section_2">
          <span class="text_1">处理列表</span>
        </div>
        <div class="flex-col section_13">
          <div class="justify-between group_13">
            <span class="text_2">零件名称</span>
            <div class="flex-row group_4">
              <span class="text_3">类型</span>
              <span class="text_4">操作</span>
            </div>
          </div>
          <!-- 表单列表 -->
          <div class="flex-col list">
            <template v-for="item in list">
              <div class="list-item justify-between" :key="item.id">
                <span>{{item.name}}</span>
                <div class="flex-row">
                  <span>{{item.operation}}</span>
                  <span class="text_7" @click="edit(item.id)">{{item.btns}}</span>
                  <span class="text_8" @click="del(item.id)">{{item.del}}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="flex-row group_14">
        <div class="flex-col items-center text-wrapper" @click="more()">
          <span>添加新零件</span>
        </div>
        <div class="flex-col items-center text-wrapper_1" @click="oko()">
          <span>完成</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {milt,del} from '@/api/Maintenance/Alarm/all'
import { Dialog } from 'vant';
import { Toast } from 'vant';
export default {
  data() {
    return {
      list: [
        // { name: '某某零件', operation: '更换', btns: '编辑', del: '删除', id: '1' },
      ]
    }
  },
  methods:{
    more(){
      this.$router.push('/maintenance/alarm/part-add/'+this.$route.params.id)
      sessionStorage.setItem('uid',this.$route.params.id)
    },
    edit(id){
      this.$router.push('/maintenance/alarm/part-edit/'+id)
      sessionStorage.setItem('uid',this.$route.params.id)
    },
    del(id){
      Dialog.confirm({
        title: '确认删除吗？',
        message: '删除后不可恢复！！！',
      }).then(() => {
          del({id:id}).then(res=>{
            console.log(res);
            if(res.data.Result===200){
              Toast('删除成功')
              location.reload()
            }else{
              Toast('删除失败')
            }
          })
        }).catch(() => {});
    },
    oko(){
      this.$router.push('/maintenance/alarm/arrive/'+this.$route.params.id)
      sessionStorage.removeItem('uid')
    }
  },
  mounted(){
    milt({id:this.$route.params.id}).then(res=>{
      console.log(res);
      if(res.data.Result===200){
        for (var i = 0; i <res.data.Data.length; i++) {
          if(res.data.Data[i].type===1){
            var str='维修'
          }else if(res.data.Data[i].type===2){
            var str='更换'
          }
          this.list.push({ name:res.data.Data[i].ProductName, operation:str, btns: '编辑', del: '删除', id:res.data.Data[i].id})
        }
      }
    })
  }
}
</script>
<style scoped>
@import '../../../assets/common.css';
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  padding-bottom: 0.94rem;
  overflow-y: auto;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.group_2 {
  height: 46.75rem;
}
.group_14 {
  margin-top: 2.19rem;
  padding: 0 1.25rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.section_2 {
  padding-left: 1.5rem;
  padding-bottom: 3.5rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
  background-image: url(../../../assets/imgbg1_03.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.section_13 {
  margin-top: -1rem;
  padding: 1.94rem 0.88rem 0.69rem;
  background: #fff;
  border-radius: 1rem;
  position: relative;
}
.text-wrapper {
  padding: 1.13rem 0 1.06rem;
  flex: 1 1 10rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background: rgb(0, 93, 219);
  border-radius: 2rem;
  height: 3.13rem;
}
.text-wrapper_1 {
  margin-left: 0.94rem;
  padding: 1.06rem 0;
  flex: 1 1 10rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background: rgb(0, 93, 219);
  border-radius: 2rem;
  height: 3.13rem;
}
.text_1 {
  margin-top: 1.88rem;
}
.group_3 {
  width: 6.44rem;
  position: relative;
}
.image_2 {
  width: 5.31rem;
  height: 6.75rem;
}
.image_3 {
  width: 3.25rem;
  height: 1.44rem;
  position: absolute;
  left: 0;
  top: 2.38rem;
}
.group_3 {
  margin-top: -1.44rem;
  padding: 0 1.25rem;
  position: relative;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.group_4 {
  margin-right: 2.25rem;
}
.text_3 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.text_4 {
  margin-left: 2.75rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.list-item {
  padding: 1.13rem 0 1.13rem 0;
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  border-bottom: 1px solid rgb(243, 249, 244);
}
.list-divider {
  margin: 0 1.25rem;
  background-color: rgb(243, 249, 244);
  height: 0.063rem;
}
.text_7 {
  margin-left: 2.81rem;
}
.text_8 {
  margin-left: 0.81rem;
}
.list {
  margin-top: 0.31rem;
}
</style>

