/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-04-01 15:29:12
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-05 17:22:04
 */
import request from '@/request/request'
// 报警维修页
// 列表
export function all(data) {
    return request({
        url:'/Maintenance/Alarm/List',
        method:'post',
        data
    })
}
export function housesList(data) {
    return request({
        url: '/Maintenance/HousesSelectList',
        method: 'post',
        data
    })
}
// 详细数据
export function detail(data){
    return request({
        url:'/Maintenance/Alarm/Info',
        method:'post',
        data
    })
}
// 接单
export function receiveOrder(data){
    return request({
        url:'/Maintenance/Alarm/ReceiveOrder',
        method:'post',
        data
    })
}
// 处理工单
export function arriveOrder(data){
    return request({
        url:'/Maintenance/Alarm/ArriveOrder',
        method:'post',
        data
    })
}
// 下拉筛选按钮  就是转单的时候把人员搞出来
export function staffSelectList(data){
    return request({
        url:'/Maintenance/CompanyStaffSelectList',
        method:'post',
        data
    })
}
// 转单
export function transferOrder(data){
    return request({
        url:'/Maintenance/Alarm/TransferOrder',
        method:'post',
        data
    })
}
// 零部件维修列表
export function milt(data){
    return request({
        url:'/Maintenance/Alarm/PartList',
        method:'post',
        data
    })
}
// 零部件维修详情
export function amin(data){
    return request({
        url:'/Maintenance/Alarm/PartInfo',
        method:'post',
        data
    })
}
// 零部件维修添加
export function add(data){
    return request({
        url:'/Maintenance/Alarm/PartSave',
        method:'post',
        data
    })
}
// 零部件维修删除
export function del(data){
    return request({
        url:'/Maintenance/Alarm/PartDel',
        method:'post',
        data
    })
}
// 完结
export function successOrder(data){
    return request({
        url:'/Maintenance/Alarm/SuccessOrder',
        method:'post',
        data
    })
}
